import '../scss/guest.scss';
import './appCommon.js';
import {setApplicationDefaults} from "./appCommon.js";

// Urls
import commonUrls from "./routes/common";
import guestUrls from "./routes/guest";

const Application = await setApplicationDefaults(null, _.concat(commonUrls, guestUrls));

Application
    .mount('#application');
