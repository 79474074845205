import {RedirectRoute, Route} from "../classes/RouteTypes";

export default [
    new RedirectRoute({path: '/', name: 'index', redirect: {name: 'timeline'}}),

    new Route({path: '/news-and-events', name: 'timeline', label: 'navigation.timeline',
        component: () => import('../pages/guest/timeline/Frame'),
        iconBefore: new URL('@media/images/vector/calendar.svg', import.meta.url).href
    }),

    new Route({path: '/introduction', name: 'introduction', label: 'navigation.introduction',
        component: () => import('../pages/guest/introduction/Frame'),
        iconBefore: new URL('@media/images/vector/law.svg', import.meta.url).href
    }),

    new Route({path: '/impressum', name: 'impressum', label: 'navigation.impressum',
        component: () => import('../pages/guest/impressum/Frame'),
        iconBefore: new URL('@media/images/vector/scale.svg', import.meta.url).href
    }),
];
